import Link from 'gatsby-plugin-transition-link'
import React from 'react'

import '../styles/header.sass'

const AniLink = Link

const English_Header = ({languageSwitchLink}) => {
    return (<header>
        <div className="header-container">

            <ul className="header-nav">
                <li>
                    <AniLink fade duration={0.2} to="/en" activeClassName="active"
                        className="hvr-underline-from-center">
                        HOME
                    </AniLink>
                </li>
                <li>
                    <AniLink fade duration={0.2} to="/en/about" activeClassName="active"
                        className="hvr-underline-from-center">
                        ABOUT
                    </AniLink>
                </li>
                <li>
                    <AniLink fade duration={0.2} to="/en/join" activeClassName="active"
                        className="hvr-underline-from-center">
                        PARTICIPATE
                    </AniLink>
                </li>
                <li>
                    <AniLink fade duration={0.2} to="/en/events" activeClassName="active"
                        className="hvr-underline-from-center">
                        ACTIVITIES
                    </AniLink>
                </li>
                <li>
                    <AniLink fade duration={0.2} to="/en/blog" activeClassName="active"
                        className="hvr-underline-from-center">
                        BLOG
                    </AniLink>
                </li>
                <li>
                    <AniLink fade duration={0.2} to="/en/team" activeClassName="active"
                        className="hvr-underline-from-center">
                        TEAM
                    </AniLink>
                </li>
                <li>
                    <AniLink fade duration={0.2} to="/en/partners" activeClassName="active"
                        className="hvr-underline-from-center">
                        PARTNERS
                    </AniLink>
                </li>
                {/*<li>
                        <AniLink fade duration={0.2} to="/en/ctf" activeClassName="active" className="hvr-underline-from-center">
                            CTF
                        </AniLink>
                    </li>*/}
                <li>
                    <AniLink fade duration={0.2} to={languageSwitchLink} activeClassName="active"
                        className="language-switch-link">
                        FR
                    </AniLink>
                </li>
            </ul>
        </div>
    </header>)
}

export default English_Header
